import React from "react";
import Seo from "../../components/seo/index";
import AdminLoginPopup from "./components/adminLoginPopup"

const Admin = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024" />
      <AdminLoginPopup/>
    </div>
  );
};

export default Admin;
